import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Navigation2 from "../components/navigation2"
import HomeContacto from "../components/home-contacto"
import { motion } from "framer-motion"

export const query = graphql`
  query($slug: String!) {
    datoCmsPaginasDeContenido(slug: { eq: $slug }) {
      titulo
      textoDeLaPagina
      fotoDeLaPagina {
        url
      }
      slug
    }
  }
`

const Content = ({ data }) => {
  const content = data.datoCmsPaginasDeContenido

  return (
    <Layout pageTitle={content.titulo}>
      <div className="w-full lg:w-10/12 mx-auto">
        <Navigation2 />

        <div className="w-full lg:flex mx-auto">
          <div className="hidden lg:block w-full lg:w-1/3 py-3 px-5 ">
            <img
              src={content.fotoDeLaPagina.url}
              alt={content.titulo}
              className=" w-full object-cover lg:p-5 lg:pt-0"
            />
          </div>
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            className="w-full lg:w-2/3 px-5 lg:px-0 mt-10 lg:mt-0"
          >
            <h1 className="text-3xl">{content.titulo}</h1>

            <div
              className="home-body"
              dangerouslySetInnerHTML={{
                __html: content.textoDeLaPagina,
              }}
            ></div>
          </motion.div>
        </div>
      </div>

      <HomeContacto />
    </Layout>
  )
}

export default Content
